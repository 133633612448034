import { Box, Typography } from '@mui/material';
import CustomLink from 'components/Buttons/CustomLinkComponent';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Wrapper = {
  alignItems: {
    xs: 'flex-start',
    md: 'center',
  },
  color: 'white',
  display: 'flex',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  fontSize: '12px',
  gap: '16px',
  justifyContent: 'space-between',
  pb: 2,
  px: {
    xs: 0,
    sm: 4,
    md: 0,
  },
};

export default function Trademark() {
  return (
    <Box sx={Wrapper}>
      <Typography variant="caption">
        {new Date().getFullYear()} &copy; Fluencypass - CNPJ: 16.668.743/0001-74
      </Typography>

      <Box
        textAlign={{ xs: 'left', md: 'right' }}
        display={{ xs: 'block', sm: 'flex' }}
      >
        <CustomLink color="inherit" href="/privacy" underline="always">
          <Typography variant="caption" component="span">
            Política de privacidade
          </Typography>
        </CustomLink>
        &nbsp;|&nbsp;
        <Typography variant="caption" component="span" mr={0.5}>
          Termos de uso:
        </Typography>
        <Typography variant="caption" component="span">
          <CustomLink color="inherit" href="/abroad-terms" underline="always">
            Intercâmbio
          </CustomLink>
        </Typography>
        &nbsp;|&nbsp;
        <Typography variant="caption" component="span">
          <CustomLink
            color="inherit"
            href="/subscription-terms"
            underline="always"
          >
            Assinatura
          </CustomLink>
        </Typography>
        &nbsp;|&nbsp;
        <Typography variant="caption" component="span">
          <CustomLink color="inherit" href="/product-terms" underline="always">
            Info-Produtos
          </CustomLink>
        </Typography>
        &nbsp;|&nbsp;
        <CustomLink
          color="inherit"
          href="#"
          underline="always"
          sx={{ display: { xs: 'block', sm: 'inline-flex' } }}
          onClick={(evt) => {
            evt.preventDefault();
            document.querySelector('#__next').scrollIntoView();
          }}
        >
          <ArrowDropUpIcon />
          <Typography variant="caption" component="span">
            Topo
          </Typography>
        </CustomLink>
      </Box>
    </Box>
  );
}
