/* eslint-disable react/require-default-props */
import Link from 'next/link';
import LinkMui from '@mui/material/Link';
import PropTypes from 'prop-types';

CustomLink.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.any.isRequired,
  prefetch: PropTypes.bool,
};

export default function CustomLink({
  href,
  as = '',
  prefetch = false,
  children,
  ...props
}) {
  return (
    <Link href={href} as={as} prefetch={prefetch} passHref legacyBehavior>
      <LinkMui underline="hover" {...props}>
        {children}
      </LinkMui>
    </Link>
  );
}
