import PropTypes from 'prop-types';
import { Box, Container, Divider } from '@mui/material';

import Links from 'components/Shared/Footer/Links';
import Trademark from 'components/Shared/Footer/Trademark';

import * as Styles from './styles';

Footer.propTypes = {
  maxWidth: PropTypes.string,
  useStyles: PropTypes.bool,
  styleProps: PropTypes.number,
};

Footer.defaultProps = {
  maxWidth: 'xl',
  useStyles: true,
};

export default function Footer({ maxWidth, useStyles, styleProps }) {
  const footerStyles = { ...Styles.bgColor, ...styleProps };

  return (
    <Box component="footer" id="footer" sx={footerStyles}>
      <Container
        maxWidth={maxWidth}
        sx={useStyles ? Styles.SectionWrapper : {}}
      >
        <Links />
      </Container>

      <Divider
        sx={{
          mb: 2,
          backgroundColor: (theme) => theme.palette.common.white,
        }}
      />

      <Container
        maxWidth={maxWidth}
        sx={useStyles ? { ...Styles.SectionWrapper, pt: 0 } : {}}
      >
        <Trademark />
      </Container>
    </Box>
  );
}
