import PhoneIcon from '@mui/icons-material/PhoneEnabled';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SpotifyIcon from 'public/assets/images/icon-spotify.png';
import { formatPhone } from 'utils/phoneUtils';

export const footerLinks = [
  {
    title: 'Sobre a Fluencypass',
    links: [
      {
        color: 'inherit',
        href: '/about',
        id: 'footer-about-link',
        text: 'A Empresa',
      },
      {
        color: 'inherit',
        href: 'https://blog.fluencypass.com/',
        id: 'footer-blog-link',
        text: 'Blog',
      },
      {
        color: 'inherit',
        href: 'https://help.fluencypass.com/',
        target: '_blank',
        text: 'Central de ajuda',
        rel: 'noopener noreferrer',
      },
      {
        color: 'inherit',
        href: 'https://jobs.fluencypass.com/',
        target: '_blank',
        text: 'Trabalhe conosco',
        rel: 'noopener noreferrer',
      },
      {
        color: 'inherit',
        href: 'https://p.fluencypass.com/talker-fluencypass/',
        target: '_blank',
        text: 'Torne-se um professor',
        rel: 'noopener noreferrer',
      },
    ],
  },
  {
    title: 'Produtos',
    links: [
      {
        color: 'inherit',
        href: '/intercambio',
        text: 'Intercâmbio',
      },
      {
        color: 'inherit',
        href: '/ingles-online',
        text: 'Inglês online + intercâmbio',
      },
      {
        color: 'inherit',
        href: 'https://questions.fluencypass.com/',
        text: 'Comunidade',
      },
    ],
  },
  {
    title: 'Materiais',
    links: [
      {
        color: 'inherit',
        href: 'https://lp.fluencypass.com/10-passos-para-o-seu-intercambio-ser-um-sucesso',
        target: '_blank',
        text: '10 passos para o seu intercâmbio ser um sucesso',
        rel: 'noopener noreferrer',
      },
      {
        color: 'inherit',
        href: 'https://lp.fluencypass.com/planilha-financeira-intercambio',
        target: '_blank',
        text: 'Modelo de Planilha financeira para intercâmbio',
        rel: 'noopener noreferrer',
      },
      {
        color: 'inherit',
        href: 'https://lp.fluencypass.com/comparativo-paises-intercambio',
        target: '_blank',
        text: 'Compare os países e descubra o melhor destino para o seu intercâmbio',
        rel: 'noopener noreferrer',
      },
      {
        color: 'inherit',
        href: 'https://lp.fluencypass.com/comparador-estudo-trabalho',
        target: '_blank',
        text: 'Compare os melhores destinos para trabalhar no exterior',
        rel: 'noopener noreferrer',
      },
    ],
  },
];

export const contacts = [
  {
    title: 'Central de Atendimento',
    links: [
      {
        icon: PhoneIcon,
        color: 'inherit',
        title: 'Central de Atendimento',
        href: `tel:${process.env.PHONE}`,
        text: formatPhone(process.env.PHONE),
      },
    ],
  },
];

export const socialmedia = [
  {
    title: 'Siga-nos',
    links: [
      {
        alt: 'Fluencypass Facebook link',
        color: 'inherit',
        href: 'https://www.facebook.com/Fluencypass/',
        icon: FacebookIcon,
        rel: 'noopener noreferrer',
        target: '_blank',
        title: 'Facebook',
      },
      {
        alt: 'Fluencypass Instagram link',
        color: 'inherit',
        href: 'https://www.instagram.com/fluencypass/',
        icon: InstagramIcon,
        rel: 'noopener noreferrer',
        target: '_blank',
        title: 'Instagram',
      },
      {
        alt: 'Fluencypass Spotify link',
        color: 'inherit',
        href: 'https://open.spotify.com/show/1rKjZKd2T2k0bBSjDec5ku',
        icon: SpotifyIcon,
        'data-custom': true,
        rel: 'noopener noreferrer',
        target: '_blank',
        title: 'Spotify',
      },
      {
        alt: 'Fluencypass Youtube link',
        color: 'inherit',
        href: 'https://www.youtube.com/c/Fluencypass',
        icon: YouTubeIcon,
        rel: 'noopener noreferrer',
        target: '_blank',
        title: 'Youtube',
      },
    ],
  },
];
