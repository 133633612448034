import Image from 'next/image';
import { Box, List, ListItem, Typography } from '@mui/material';
import CustomLink from 'components/Buttons/CustomLinkComponent';

import { footerLinks, contacts, socialmedia } from 'mocks/footer';

export default function Links() {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="flex-start"
      justifyContent="space-between"
      gap="16px"
    >
      {footerLinks.map((section) => (
        <List disablePadding key={`${section.title}-key`}>
          <ListItem disablePadding sx={{ mb: 3 }}>
            <Typography
              components="h4"
              sx={{ color: '#FB5A5E', fontWeight: 600 }}
            >
              {section.title}
            </Typography>
          </ListItem>

          {section.links.map((link) => (
            <ListItem disablePadding key={`${link.href}-key`} sx={{ mb: 3 }}>
              <CustomLink {...link}>
                <Typography
                  variant="body2"
                  component="h4"
                  sx={{ color: 'white' }}
                >
                  {link.text}
                </Typography>
              </CustomLink>
            </ListItem>
          ))}
        </List>
      ))}

      <List disablePadding>
        {contacts.map((section) => (
          <ListItem disablePadding key={`${section.title}-key`}>
            <List disablePadding>
              <ListItem disablePadding sx={{ mb: 3 }}>
                <Typography
                  components="h4"
                  variant="body2"
                  sx={{ color: '#FB5A5E', fontWeight: 600 }}
                >
                  {section.title}
                </Typography>
              </ListItem>

              {section.links.map((link) => (
                <ListItem
                  disablePadding
                  key={`${link.href}-key`}
                  sx={{ mb: 3 }}
                >
                  <CustomLink {...link}>
                    <Typography
                      component="h4"
                      variant="body2"
                      sx={{
                        color: 'white',

                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <link.icon sx={{ mr: 1 }} /> {link.text}
                    </Typography>
                  </CustomLink>
                </ListItem>
              ))}
            </List>
          </ListItem>
        ))}

        <ListItem disablePadding>
          {socialmedia.map((social) => (
            <List disablePadding key={`${social.title}-key`}>
              <ListItem disablePadding sx={{ mb: 3 }}>
                <Typography
                  components="h4"
                  variant="body2"
                  sx={{ color: '#FB5A5E', fontWeight: 600 }}
                >
                  {social.title}
                </Typography>
              </ListItem>

              <ListItem disablePadding>
                <List sx={{ display: 'flex', gap: '16px' }}>
                  {social.links.map((link) => (
                    <ListItem
                      disablePadding
                      key={`${link.href}-key`}
                      sx={{ mb: 3 }}
                    >
                      <CustomLink {...link}>
                        <Typography
                          component="h4"
                          variant="body2"
                          sx={{ color: 'white' }}
                        >
                          {link['data-custom'] ? (
                            <Image
                              loader={({ src, width }) => `${src}?w=${width}`}
                              src={link.icon}
                              alt={link.alt}
                              width={24}
                              height={24}
                            />
                          ) : (
                            <link.icon />
                          )}
                        </Typography>
                      </CustomLink>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </List>
          ))}
        </ListItem>
      </List>
    </Box>
  );
}
